import { Link } from "@remix-run/react";
import { ReconfBrandLogo } from "@/components/gen-brand";

export const HomeButton = ({ to }: { readonly to?: string }) => {
  return (
    <Link className="flex flex-col items-center p-2" to={to ?? "/"}>
      <ReconfBrandLogo className="h-[1.2rem] w-[1.2rem]" />
    </Link>
  );
};

export const SecurityLink = ({
  className,
}: {
  readonly className?: string | undefined;
}) => {
  return (
    <Link
      className={className}
      target="_blank"
      to="https://reconfigured.notion.site/public-architecture-and-security-5fc11e89eeb2459c8d31f386fc050e9c?pvs=74"
    >
      Security and privacy documentation
    </Link>
  );
};

export const PrivacyPolicyLink = ({
  className,
}: {
  readonly className?: string | undefined;
}) => {
  return (
    <Link
      className={className}
      target="_blank"
      to="https://www.iubenda.com/privacy-policy/28140397/full-legal"
    >
      Privacy Policy
    </Link>
  );
};

export const TermsAndConditionsLink = ({
  className,
}: {
  readonly className?: string | undefined;
}) => {
  return (
    <Link
      className={className}
      target="_blank"
      to="https://www.iubenda.com/terms-and-conditions/28140397"
    >
      Terms and Conditions
    </Link>
  );
};
